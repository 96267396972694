<template>
  <div class="my-profile">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-col class="form" :offset="6" :span="12">
      <el-card class="profile-card" shadow="hover">
        <div slot="header">
          <span>{{ $t("route.profile") }}</span>
        </div>
        <el-row>
          <div class="summary-title">{{ $t("common.username") }} :</div>
          {{ my ? my.userName : "" }}
        </el-row>
        <el-row>
          <div class="summary-title">{{ $t("common.email") }} :</div>
          {{ my ? my.email : "" }}
        </el-row>
        <el-row>
          <el-button
            class="btn"
            type="primary"
            size="mini"
            @click="dialogVisible = true"
            >{{ $t("profile.changePassword") }}</el-button
          >
        </el-row>
      </el-card>
    </el-col>
    <el-dialog
      :title="$t('profile.changePassword')"
      :visible.sync="dialogVisible"
      custom-class="password-dialog"
      v-loading="loading"
    >
      <el-form
        ref="form"
        :model="form"
        status-icon
        :rules="changePasswordRules"
        size="small"
        label-width="auto"
      >
        <el-form-item
          :label="$t('profile.originalPassword')"
          prop="originalPassword"
          required
        >
          <el-input show-password v-model="form.originalPassword"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('profile.newPassword')"
          prop="newPassword"
          required
        >
          <el-input
            show-password
            v-model="form.newPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('profile.confirmPassword')"
          prop="confirmPassword"
          required
        >
          <el-input
            show-password
            v-model="form.confirmPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="dialogVisible = false">{{
          $t("common.cancel")
        }}</el-button>
        <el-button type="primary" size="small" @click="onSubmit">{{
          $t("common.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { changePassword } from "@/api/user";

export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input new password"));
      } else if (value == this.form.originalPassword) {
        callback(new Error("Original and new password are same"));
      } else {
        if (this.form.checkPass !== "") {
          this.$refs.form.validateField("confirmPassword");
        }
        callback();
      }
    };
    var validatePassConfirm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input new password again"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("The two passwords are inconsistent"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        originalPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      changePasswordRules: {
        originalPassword: [
          {
            required: true,
            message: "Please input original password",
            trigger: "blur",
          },
        ],
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        confirmPassword: [{ validator: validatePassConfirm, trigger: "blur" }],
      },
      loading: false,
    };
  },
  computed: {
    my() {
      return this.$store.getters.my;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          changePassword(this.form)
            .then((response) => {
              this.loading = false;
              if (response.success) {
                this.$notify({
                  title: "Change password successful",
                  type: "success",
                });
                this.dialogVisible = false;
                this.$refs.form.resetFields();
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-profile {
  margin: 10px 20px;

  .form {
    padding-top: 10vh;

    .el-row {
      margin-bottom: 10px;

      .summary-title {
        display: inline;
        font-weight: bold;
        margin-right: 8px;
      }
    }

    .el-row:last-child {
      margin-bottom: 0;
    }
  }

  .password-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 90%;
  }
}
</style>